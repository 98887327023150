<template>
    <div>
        <Personal />
    </div>
</template>
<script>
import Personal from '@/components/Forms/Personal.vue';

export default {
  name: 'AddUser',
  components: {
    Personal,
  },
};
</script>
