<template>
  <section class="section">
    <div v-if="userCreated">
      <h1 class="title">
        Candidate
        <strong class="has-text-danger">{{ `${title} ${firstName} ${lastName}` }}</strong>
        has been created
      </h1>
    </div>
    <div v-if="!userCreated">
      <div class="container box">
        <h1 class="title has-text-centered">Personal Details</h1>
        <div class="columns is-centered has-text-left">
          <div class="column is-three-quarters">
            <div class="field is-horizontal" id="name-field">
              <div class="field-label is-normal has-text-left">
                <label class="label">Name</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-select
                      id="title"
                      @input="updateTitle"
                      :value="title"
                      ref="title"
                      placeholder="Title"
                      required
                    >
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Ms">Ms</option>
                      <option value="Miss">Miss</option>
                      <option value="Dr">Dr</option>
                      <option value="Prof">Prof</option>
                      <option value="Other">Other</option>
                    </b-select>
                  </b-field>
                </div>
                <div class="field">
                  <b-field>
                    <ValidationProvider
                      rules="namePart"
                      :immediate="validateInitialState"
                      v-slot="{ errors }"
                    >
                      <b-input
                        @input="updateFirstName"
                        id="first-name"
                        ref="first-name"
                        :value="firstName"
                        placeholder="First Name"
                        type="text"
                        minlength="2"
                        maxlength="35"
                        required
                      ></b-input>
                      <span class="error-message has-text-danger">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="field">
                  <b-field>
                    <ValidationProvider
                      rules="namePart"
                      :immediate="validateInitialState"
                      v-slot="{ errors }"
                    >
                      <b-input
                        @input="updateMiddleName"
                        id="middle-name"
                        ref="middle-name"
                        :value="middleName"
                        placeholder="Middle Name"
                        type="text"
                        minlength="2"
                        maxlength="35"
                      ></b-input>
                      <span class="error-message has-text-danger">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="field">
                  <b-field>
                    <ValidationProvider
                      rules="namePart"
                      :immediate="validateInitialState"
                      v-slot="{ errors }"
                    >
                      <b-input
                        @input="updateLastName"
                        id="last-name"
                        ref="last-name"
                        :value="lastName"
                        placeholder="Last Name"
                        type="text"
                        minlength="2"
                        maxlength="35"
                        required
                      ></b-input>
                      <span class="error-message has-text-danger">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
            </div>

            <div class="field is-horizontal" id="dob-field">
              <div class="field-label is-normal has-text-left">
                <label class="label">Date of Birth</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-datepicker
                      @input="updateDOB"
                      id="date-of-birth"
                      ref="date-of-birth"
                      placeholder="Click to select date of birth..."
                      :value="DOB"
                      :date-formatter="
                        (date) => new Intl.DateTimeFormat('in-IN').format(date)
                      "
                      icon="calendar-today"
                      trap-focus
                    ></b-datepicker>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="field is-horizontal" id="ni-number-field">
              <div class="field-label is-normal has-text-left">
                <label class="label">NI Number</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <ValidationProvider
                      rules="NI"
                      :immediate="validateInitialState"
                      v-slot="{ errors }"
                    >
                      <b-input
                        @input="updateNI"
                        id="national-insurance"
                        ref="national-insurance"
                        :value="NI"
                        minlength="6"
                        maxlength="15"
                        type="text"
                      ></b-input>
                      <span class="error-message has-text-danger">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="field is-horizontal" id="registration-number-field">
              <div class="field-label is-normal has-text-left">
                <label class="label">Card registration Number</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <ValidationProvider
                      rules="NI"
                      :immediate="validateInitialState"
                      v-slot="{ errors }"
                    >
                      <b-input
                        @input="updateRegistrationNumber"
                        id="registration-number"
                        ref="registration-number"
                        :value="cardRegistrationNumber"
                        type="text"
                        minlength="6"
                        maxlength="15"
                      ></b-input>
                      <span class="error-message has-text-danger">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <h1 class="title">Contact Details</h1>
          </div>
        </div>

        <div class="columns is-centered has-text-left">
          <div class="column is-three-quarters">
            <div class="field is-horizontal">
              <div class="field-body" id="contact-details">
                <div class="field">
                  <b-field>
                    <ValidationProvider
                      rules="phone"
                      :immediate="validateInitialState"
                      v-slot="{ errors }"
                    >
                      <b-input
                        placeholder="Phone Number *"
                        @input="updatePhoneNumber"
                        id="phone-number"
                        ref="phone-number"
                        :value="phoneNumber"
                        type="tel"
                        validation-message="Please check the number you have entered"
                        required
                        pattern="[0-9]*"
                        minlength="10"
                        maxlength="11"
                        icon-pack="fas"
                        icon="phone"
                      ></b-input>
                      <span class="error-message has-text-danger">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
                <div class="field">
                  <b-field>
                    <ValidationProvider
                      rules="email"
                      :immediate="validateInitialState"
                      v-slot="{ errors }"
                    >
                      <b-input
                        placeholder="Email *"
                        @input="updateEmail"
                        id="email"
                        ref="email"
                        :value="email"
                        required
                        type="email"
                        icon-pack="fas"
                        icon="envelope"
                      ></b-input>
                      <span class="error-message has-text-danger">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-field>
                  <b-collapse
                    class="mt-2"
                    :open="false"
                    position="is-bottom"
                    aria-id="additionalEmail"
                  >
                    <template #trigger="props">
                        <a class="button is-info is-inverted" aria-controls="additionalEmail">
                            {{ !props.open ? 'Add Alternate Email' : '' }}
                        </a>
                    </template>
                    <b-field>
                      <ValidationProvider
                        rules="email"
                        :immediate="validateInitialState"
                        v-slot="{ errors }"
                      >
                        <b-input
                          placeholder="Alternate Email"
                          @input="updateAltEmail"
                          id="alt-email"
                          ref="alt-email"
                          :value="alternateEmail"
                          type="email"
                          icon-pack="fas"
                          icon="envelope"
                        ></b-input>
                        <span class="error-message has-text-danger">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                  </b-field>
                  </b-collapse>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-centered">
          <div class="column is-three-quarters">
            <h1 class="title">Address Details</h1>
          </div>
        </div>

        <div class="columns is-centered has-text-left">
          <div class="column is-three-quarters">
            <div class="field is-horizontal" id="address-field">
              <div class="field-label is-normal has-text-left">
                <label class="label">Address 1</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <ValidationProvider
                      rules="address"
                      :immediate="validateInitialState"
                      v-slot="{ errors }"
                    >
                      <b-input
                        placeholder="House number and Street name..."
                        @input="updateAddress"
                        id="address"
                        ref="address"
                        :value="address"
                        type="text"
                        minlength="1"
                        maxlength="80"
                        required
                      ></b-input>
                      <span class="error-message has-text-danger">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="field is-horizontal" id="town-field">
              <div class="field-label is-normal has-text-left">
                <label class="label">Town/City</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <ValidationProvider
                      rules="town"
                      :immediate="validateInitialState"
                      v-slot="{ errors }"
                    >
                      <b-input
                        id="town"
                        ref="town"
                        @input="updateTown"
                        :value="town"
                        type="text"
                        minlength="2"
                        maxlength="30"
                        required
                      ></b-input>
                      <span class="error-message has-text-danger">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
            </div>
            <div class="field is-horizontal" id="postcode-field">
              <div class="field-label is-normal has-text-left">
                <label class="label">Postcode</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <ValidationProvider
                      rules="postcode"
                      :immediate="validateInitialState"
                      v-slot="{ errors }"
                    >
                      <b-input
                        id="postcode"
                        ref="postcode"
                        @input="updatePostCode"
                        :value="postCode"
                        type="text"
                        minlength="5"
                        maxlength="10"
                        required
                      ></b-input>
                      <span class="error-message has-text-danger">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </b-field>
                </div>
              </div>
            </div>

            <div class="field is-horizontal">
          <div class="field-label is-normal has-text-left">
            <label class="label">Trade</label>
          </div>
          <div class="field-body">
            <div class="field">
              <b-field>

                <b-autocomplete
                  v-model="trade"
                  ref="autocomplete"
                  :data="filteredTradeArray"
                  @select="updateTrade"
                  :selectable-footer="false">
                  <template #footer>
                      <a @click="showAddTrade"><span> Add new... </span></a>
                  </template>
                  <template #empty>No results for {{trade}}</template>
                </b-autocomplete>
              </b-field>
            </div>
          </div>
        </div>

            <div class="field is-horizontal" id="status-field">
              <div class="field-label is-normal has-text-left">
                <label class="label">Status</label>
              </div>
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-select
                      @input="updateStatus"
                      :value="status"
                      id="status"
                      ref="status"
                      expanded
                    >
                      <option value="Open">Open</option>
                      <option value="Not Interested">Not Interested</option>
                      <option value="Answering Machine">
                        Answering Machine
                      </option>
                      <option value="No Answer">No Answer</option>
                      <option value="Lead Dial 1">Lead Dial 1</option>
                      <option value="Lead Dial 2">Lead Dial 2</option>
                      <option value="Lead Dial 3">Lead Dial 3</option>
                      <option value="Sale">Sale</option>
                      <option value="Duplicate">Duplicate</option>
                      <option value="Not Reachable">Not Reachable</option>
                      <option value="Callback">Callback</option>
                      <option value="Pending Payment">Pending Payment</option>
                      <option value="Customer Callback">
                        Customer Callback
                      </option>
                      <option value="Miscellaneous">Miscellaneous</option>
                    </b-select>
                  </b-field>
                </div>
              </div>
            </div>

            <div class="field is-horizontal has-text-centered">
              <div class="field-body">
                <div class="field">
                  <b-field>
                    <b-button
                      id="add-personal"
                      @click="addUserPersonal"
                      expanded
                      icon-right="arrow-circle-right"
                      type="is-info"
                    >Add User</b-button>
                  </b-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { validate, createSnackbar } from '@/common';
import { occupations } from '@/assets/data';

export default {
  name: 'Personal',
  data: () => ({
    userCreated: false,
    validateInitialState: true,
    trade: '',
    trades: occupations,
  }),
  computed: {
    ...mapState({
      title: (state) => state.personalDetails.title,
      firstName: (state) => state.personalDetails.firstName,
      middleName: (state) => state.personalDetails.middleName,
      lastName: (state) => state.personalDetails.lastName,
      DOB: (state) => state.personalDetails.DOB,
      NI: (state) => state.personalDetails.NI,
      cardRegistrationNumber: (state) => state.personalDetails.cardRegistrationNumber,
      phoneNumber: (state) => state.personalDetails.phoneNumber,
      email: (state) => state.personalDetails.email,
      alternateEmail: (state) => state.personalDetails.alternateEmail,
      address: (state) => state.personalDetails.address,
      town: (state) => state.personalDetails.town,
      postCode: (state) => state.personalDetails.postCode,
      status: (state) => state.personalDetails.status,
    }),
    filteredTradeArray() {
      return this.trades.filter(
        (option) => option
          .toString()
          .toLowerCase()
          .indexOf(this.trade.toLowerCase()) >= 0,
      );
    },
  },
  methods: {
    createSnackbar,
    ...mapActions([
      'setTitle',
      'setFirstName',
      'setMiddleName',
      'setLastName',
      'setDOB',
      'setNI',
      'setCardRegistrationNumber',
      'setPhoneNumber',
      'setEmail',
      'setAlternateEmail',
      'setAddress',
      'setTown',
      'setPostCode',
      'setTrade',
      'setStatus',
      'addCandidate',
    ]),
    updateTitle(e) {
      this.setTitle(e);
    },
    updateFirstName(e) {
      this.setFirstName(e);
    },
    updateMiddleName(e) {
      this.setMiddleName(e);
    },
    updateLastName(e) {
      this.setLastName(e);
    },
    updateDOB(e) {
      this.setDOB(e);
    },
    updateNI(e) {
      this.setNI(e);
    },
    updateRegistrationNumber(e) {
      this.setCardRegistrationNumber(e);
    },
    updatePhoneNumber(e) {
      this.setPhoneNumber(e);
    },
    updateEmail(e) {
      this.setEmail(e);
    },
    updateAltEmail(e) {
      this.setAlternateEmail(e);
    },
    updateAddress(e) {
      this.setAddress(e);
    },
    updateTown(e) {
      this.setTown(e);
    },
    updatePostCode(e) {
      this.setPostCode(e);
    },
    updateStatus(e) {
      this.setStatus(e);
    },
    updateTrade(e) {
      this.setTrade(e);
    },
    showAddTrade() {
      this.$buefy.dialog.prompt({
        message: 'Add New Trade',
        inputAttrs: {
          placeholder: 'e.g. Labourer',
          maxlength: 60,
          value: this.trade,
        },
        confirmText: 'Add',
        onConfirm: (value) => {
          this.trades.push(value);
          this.$refs.autocomplete.setSelected(value);
        },
      });
    },
    async addUserPersonal() {
      const invalidFields = validate({
        title: this.title,
        'first-name': this.firstName,
        'last-name': this.lastName,
      });

      if (!invalidFields.length) {
        try {
          const id = await this.addCandidate();
          this.userCreated = true;
          this.$router.push({ name: 'LeadDetail', params: { userId: id } });
          return this.createSnackbar('User has been created', true);
        } catch (error) {
          console.error(error);
          return this.createSnackbar('Error in data');
        }
      } else {
        return this.createSnackbar(`Error in ${invalidFields[0]}`);
      }
    },
  },
  // components: {
  //   ValidationProvider,
  // },
};
</script>
